import React from "react";
import "./style.css";

export default ({ image, alt, position, location, link, btnText }) => (
  <React.Fragment>
    <div className="careers-block-wrapper">
      <img src={ image } alt={ alt } className="careers-block-image" />
      <div className="careers-detail-wrapper">
        <h3 className="careers-detail-position">
          { position }
        </h3>
        <p className="careers-detail-location">
          { location }
        </p>
      </div>
      <a href={ link } className="careers-posting-button">
        { btnText }
      </a>
    </div>
  </React.Fragment>
)
import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import SimpleHero from "../components/SimpleHero";
import JobPost from "../components/JobPost";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

class Careers extends Component {
   render() {

    const {
      data: { seo, hero, jobs },
    } = this.props

    const seoData = seo.primary;

    const heroData = hero.data;

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container">
          <NavBar />
          <SimpleHero
            title={ heroData.careers_page_title.text 
            }
            teaser={ heroData.careers_page_teaser.text }
            />
          <h3 className="careers-section-title">
            { heroData.careers_positions_title.text }
          </h3>
          <div className="careers-section-wrapper">
            {jobs.data.job_listings.map((j, i) => {
              const jobData = j.job_listing.document.data;
              return (
                <JobPost
                  image={ jobData.job_image.url }
                  alt={ jobData.job_image.alt }
                  position={ jobData.job_title.text }
                  location={ jobData.job_location.text }
                  link={ jobData.job_button_link.url }
                  btnText={ jobData.job_button_label.text }
                  />
              )
            })}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
   }
}

export default Careers

Careers.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      primary: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        seo_description: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      data: PropTypes.shape({
        careers_page_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        careers_page_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      }),
    }),
    jobs: PropTypes.shape({
      data: PropTypes.shape({
        job_listings: PropTypes.array.isRequired,
      }),
    }),
  }),
}

export const careersQuery = graphql`
  query careersPage {

    seo: prismicCareersPageBodySeoTags {
      primary {
        seo_title
        seo_description
      }
    }

    hero: prismicCareersPage {
      data {
        careers_page_title {
          text
        }
        careers_page_teaser {
          text
        }
        careers_positions_title {
          text
        }
      }
    }

    jobs: prismicCareersPage {
      data {
        job_listings {
          job_listing {
            document {
              ... on PrismicJobPosting {
                data {
                  job_image {
                    url
                    alt
                  }
                  job_title {
                    text
                  }
                  job_location {
                    text
                  }
                  job_button_link {
                    url
                  }
                  job_button_label {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }
`